/* .view-dropdown-select { */
/* width: 250px;
  height: auto;
  padding: 18px 12px 18px 12px;
  font-size: 18px;
  font-weight: 650;
  position: relative;
  color: #212121cc;
  background-color: inherit;
  margin-top: 10px;
  text-transform: capitalize; */
/* } */
.view-dropdown-select {
  width: 7rem;
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  font-size: 14px;
  padding: 11px 20px;
  color: #333;
  background-color: #ffe5d9;
  box-shadow: rgb(0 0 0 / 15%) 0 0 0 1px inset;
  appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, #333 50%),
    linear-gradient(135deg, #333 50%, transparent 50%),
    linear-gradient(to right, #dac3b8, #dac3b8);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}
/* .all-filters {
  background: url('../../assets/all-filter-icon.svg') no-repeat;
  background-position: right 10px top 50%;
  appearance: none;
  background-color: rgba(228, 193, 177, 1);
} */
