.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0rem !important;
}

.button-bookmark {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
