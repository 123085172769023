* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  position: relative;
  width: 100%;
  margin: 0;
}
.app {
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px; /* height of your footer */
  text-align: center;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

h1 {
  font-weight: 900;
  font-size: 2.5rem;
}

input[type='text'] {
  height: 2.5rem;
  padding: 1rem 1rem;
  min-width: 15rem;
  border-radius: 5px;
  border: none;
}

input[type='submit'] {
  padding: 1rem 1rem;
  border-radius: 5px;
  border: none;
}

a {
  color: black;
  text-decoration: none;
}

a:visited {
  color: black;
}
