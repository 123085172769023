.add-app-container {
  width: 100%;
  flex-direction: column;
}

.submit-box {
  width: 42rem;
  max-width: 42rem;
  margin-top: 2rem;
}

.btn-add-prompt {
  margin-top: 1rem;
}
