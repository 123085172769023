.login-container {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__textBox {
  padding: 10px;
  font-size: 14px;
  margin-bottom: 10px;
}
.login__btn {
  padding: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}
.login__google {
  background-color: #4285f4;
}
.login div {
  margin-top: 7px;
}

.login-box input[type='text'],
.login-box input[type='password'] {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.login-box input[type='password'] {
  border: none;
  border-radius: 5px;
}
