.form-input {
  border-style: hidden;
  border-bottom: 1px solid rgba(33, 33, 33, 0.4);
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  height: 2rem;
  background: #f2ebe7;
  text-align: start;
  color: #212121;
  padding-left: 0.5rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.form-input::placeholder {
  padding-left: 0;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.error-message {
  font-size: 0.75rem;
  font-weight: bold;
  color: red;
  border: none;
}

textarea {
  width: 100%;
  height: 5rem !important;
  font-family: 'Inter', sans-serif;
  padding: 15px 10px;
}

textarea::placeholder {
  font-size: 1rem !important;
}
