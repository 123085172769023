.container-appview {
  text-align: left;
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: start;
}
.container-bookmark {
  display: flex;
  align-items: center;
}

.container-bookmark,
.container-title {
  justify-content: space-between;
  width: 100%;
}
.container-description {
  width: 100%;
}
.container-rating,
.container-title {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.container-appview h1,
.container-appview h3 {
  margin: 0 !important;
}

.container-appview p {
  line-height: 1.5rem;
}
.prompt-title-view {
  font-size: 1.4rem;
}

.icons-apps-page {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.container-details {
  padding: 0.5rem;
  background-color: #ffe5d9;
  border-radius: 5px;
  border: 1px solid #efefef;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-size: 0.9rem;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.container-details p {
  margin: 0.5rem;
}

.container-details h2 {
  margin-left: 0.5rem;
}

.container-tags {
  display: flex;
  gap: 1rem;
}

.container-alternatives {
  width: 100%;
}

.searches {
  margin-top: 1rem;
}

.button-back {
  margin-top: 3rem;
}

.comment-box {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 2rem 2.5rem;
  gap: 0.7rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.comment-author-date {
  font-size: 0.75rem;
  color: #828282;
}

.simple-link {
  text-decoration: underline;
}

.appview-image {
  width: 100%;
  max-height: 30rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.small-cards {
  margin-top: 1rem;
}

.cta {
  flex-direction: row;
  align-items: center;

  gap: 20rem;
}

.badges {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

@media only screen and (max-width: 992px) {
  .container-appview {
    width: 90%;
    margin: 0 auto;
  }
}
