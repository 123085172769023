.category-input {
  display: flex;
  gap: 0.2rem;
}

.category-input .label {
  width: 100%;
  display: flex;
  cursor: pointer;
}

.category-input span {
  flex: 0 0 95%;
}

.chevron {
  width: 0.6rem;
  padding-bottom: 0.2rem;
}

.open {
  transform: rotate(180deg);
  transition: transform 0.4s linear;
}
